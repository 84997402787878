import React from 'react'
import { Location } from '@reach/router'

// eslint-disable-next-line react/display-name
export const withLocation = (Wrapped: React.JSXElementConstructor<any>) => (
  wrappedProps: any
) => (
  <Location>
    {(location) => <Wrapped {...wrappedProps} location={location} />}
  </Location>
)
