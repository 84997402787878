import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { DsNextChapter } from '@rangleio/ds';

export function NextChapter({
  ctaHeading,
  ctaLinkText,
  ctaSlug,
  navigationHeading,
  navigationLinkText,
  navigationSlug,
}) {
  return (
    <DsNextChapter
      ctaHeading={ctaHeading}
      ctaLinkText={ctaLinkText}
      ctaSlug={ctaSlug}
      navigationHeading={navigationHeading}
      navigationLinkText={navigationLinkText}
      navigationSlug={navigationSlug}
      frameworkLinkTag={GatsbyLink}
    ></DsNextChapter>
  );
}

export default NextChapter;
