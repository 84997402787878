import React from 'react'
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { Node, NodeWithChildren } from 'domhandler'
import styled from 'styled-components'
import { mq } from '../../../theme'
import { DsGrid } from '../../Organisms/DsGrid'
import { DsLink, DsTable } from '../../..'

export interface DsRichTextProps {
  content: string,
  frameworkLinkTag?: React.ReactNode,
}

const StyledContainer = styled.div(() =>
  mq({
    'p:not(.ds-editorial)': {
      width: '100%'
    }
  })
)

export function DsRichText({ content = '', frameworkLinkTag }: DsRichTextProps) {
  const getTableItems = (children: any, tableType: 'th' | 'td') => {
    const items = (
      children?.filter(
        ({ name }: any) => name === 'tbody'
      )[0] as NodeWithChildren
    )?.children
      .filter(({ name }: any) => name === 'tr')
      .map((tr: NodeWithChildren) =>
        tr.children
          .filter(({ name }: any) => name === tableType)
          .map(({ children }: NodeWithChildren) => domToReact(children, options))
      )
    return items;
  }

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      let children: Node[] | undefined
      let name: string | undefined
      let attribs: any | undefined
      let data: any | undefined
      let linkData: any | undefined

      if (domNode !== undefined && 'children' in domNode) {
        children = domNode.children
        data = domNode
        linkData = data?.children[0]?.data
      }
      if (domNode !== undefined && 'name' in domNode) {
        name = domNode.name
      }
      if (domNode !== undefined && 'attribs' in domNode) {
        attribs = domNode.attribs
      }

      switch (name) {
        case 'a':
          const url = attribs.href
          return <DsLink url={url} variant='Inline Link' label={linkData} frameworkLinkTag={frameworkLinkTag} />
        case 'table':
          const rows: any[] = getTableItems(children, 'td');
          const columns: any[] = getTableItems(children, 'th');
          if (attribs?.class === "cms-grid") {
            const columnRatio = rows[0].map(() => '1').join(':');
            const gridItems = rows.flat();
            return <DsGrid columnRatio={columnRatio} gridItems={gridItems} />
          }
          let tableHeaders: string[];
          if (columns[0].length === 0) {
            tableHeaders = rows[0];
            rows.shift();
          } else {
            tableHeaders = columns[0];
          }
          return <DsTable tableHeaders={tableHeaders} tableContent={rows} />
        default:
          return;
      }
    }
  }

  return (
    <StyledContainer className='ds-rich-text'>
      {parse(`${content}`, options)}
    </StyledContainer>
  )
}

export default DsRichText

