import React from 'react'
import { DsLink } from '@rangleio/ds'
import { Link as GatsbyLink } from 'gatsby'

export const Link = ({ label, url, variant }) => (
  <DsLink
    frameworkLinkTag={GatsbyLink}
    label={label}
    url={url}
    variant={variant}
  />
)

export default Link
