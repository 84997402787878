import React from 'react'
import { DsVideo } from '@rangleio/ds'

export const Video = ({ 
  video = [], 
  autoplay,
  muted
 }) => {
  return (
    <DsVideo
      src={video[0]?.original_secure_url}
      autoplay={autoplay}
      muted={muted}
    />
  )
}

export default Video
