import React from 'react'
import { DsRichText } from '@rangleio/ds'
import { Link as GatsbyLink } from 'gatsby'

export const RichText = ({ content }) => (
  <DsRichText
    frameworkLinkTag={GatsbyLink}
    content={content}
  />
)

export default RichText;
