import facepaint from 'facepaint'

export const theme = {
  colors: {
    grayscale: {
      darker: '#262626',
      dark: '#4D4D4D',
      medium: '#737373',
      light: '#D9D9D9',
      lighter: '#FAFAFA',
      white: '#FFFFFF'
    },
    redscale: {
      darker: '#D44527',
      dark: '#F65836',
      medium: '#F67055',
      light: '#F7856E',
      lighter: '#FAAE9E'
    }
  },
  mediaQuery: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1280px',
    xlDesktop: '2560px'
  },
  spacing: {
    xxxSmall: ['0.25rem'],
    xxSmall: ['0.5rem'],
    xSmall: ['1rem'],
    small: ['1rem', '1rem', '1.5rem'],
    medium: ['1.5rem', '1.5rem', '2rem'],
    large: ['2rem', '2rem', '3rem'],
    xLarge: ['4.5rem', '4.5rem', '9rem'],
    xxLarge: ['9rem', '9rem', '18rem']
  },
  transitions: {
    durations: {
      slow: '0.75s',
      medium: '0.5s',
      fast: '0.15s'
    }
  }
}

export const mq = facepaint(
  Object.values(theme.mediaQuery).map((mq) => `@media(min-width: ${mq})`)
)
