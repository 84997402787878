import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'
import { useRecoilState, useRecoilValue } from 'recoil'
import { calcState, promptResultState } from '../../../state'

export interface DsCalculatorPromptProps {
  name?: string
}

const StyledContainer = styled.div(() =>
  mq({
    marginBottom: theme.spacing.small,
    marginTop: theme.spacing.medium,
    width: ['100%', '100%', '650px']
  })
)

const StyledPair = styled.div(() =>
  mq({
    display: ['block', 'block', 'flex'],
    justifyContent: ['initial', 'initial', 'space-between']
  })
)

const StyledLabel = styled.label(() =>
  mq({
    fontWeight: '200 !important',
    paddingTop: '12px',
    width: ['300px', '300px', '70%']
  })
)

const StyledNum = styled.div(() =>
  mq({
    fontWeight: '200 !important',
    paddingBottom: ['12px', '12px', '0px'],
    paddingTop: ['0px', '0px', '12px']
  })
)

const StyledInput = styled.input(() =>
  mq({
    background: theme.colors.grayscale.darker,
    border: `1px solid ${theme.colors.grayscale.lighter}`,
    color: theme.colors.grayscale.lighter,
    height: '20px',
    justifyContent: ['start', 'start', 'right'],
    marginBottom: theme.spacing.xxSmall,
    marginTop: theme.spacing.xxSmall,
    paddingBottom: '12px',
    paddingTop: '12px',
    textAlign: 'right',
    width: ['95%', '95%', '150px']
  })
)

const StyledFormula = styled.div(() =>
  mq({
    boxShadow: `40px -20px ${theme.colors.grayscale.dark}`,
    backgroundColor: theme.colors.grayscale.darker,
    color: theme.colors.grayscale.light,
    display: ['block', 'block', 'inline-block'],
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing.small,
    width: ['100%', '100%', '450px', '700px', '850px']
  })
)

const StyledSuperScript = styled.div(() =>
  mq({
    color: `${theme.colors.grayscale.light} !important`,
    marginBottom: ['8px !important', '8px !important', '0px !important'],
    marginTop: ['-5px !important', '-5px !important', '-19px !important'],
    paddingBottom: '0',
    paddingTop: '0',
    width: ['100%', '100%', '70%']
  })
)

export function DsCalculatorPrompt({}: DsCalculatorPromptProps) {
  interface Inputs {
    name: string
    placeholder: string
  }

  // function for the input boxes; as the input changes, it will update
  // the state values
  function CalcInput(props: Inputs) {
    const [prompt, setPrompt] = useRecoilState(calcState)

    const formChange = (event: any) => {
      if (!isNaN(event.target.value)) {
        setPrompt((currentState: any) => ({
          ...currentState,
          remediation: {
            ...currentState.remediation,
            [event.target.name]: event.target.value
          }
        }))
      }
    }

    return (
      <StyledInput
        name={props.name}
        placeholder={props.placeholder}
        onChange={(e) => formChange(e)}
        autoComplete='off'
      />
    )
  }

  return (
    <StyledContainer>
      <StyledFormula>
        <h2 style={{ color: theme.colors.grayscale.light }}>Getting Started</h2>
        <StyledLabel>Tell us a little bit about your organization:</StyledLabel>
        <br />
        <br />
        <form>
          <StyledPair>
            <StyledLabel className='ds-eyebrow-headline'>
              Estimated Digital Team Size:
            </StyledLabel>
            <CalcInput name='staff' placeholder='850' />
          </StyledPair>
          <StyledPair>
            <StyledLabel className='ds-eyebrow-headline'>
              Estimated Average Salary:
            </StyledLabel>
            <CalcInput name='salary' placeholder='120000' />
          </StyledPair>
          <StyledPair>
            <StyledLabel className='ds-eyebrow-headline'>
              Estimated Benefits Multiplier:
            </StyledLabel>
            <CalcInput name='benefits' placeholder='1.5' />
          </StyledPair>
          <StyledSuperScript className='ds-small-body'>
            Benefits multiplier represents employee benefits such as insurance,
            vacation, pensions, etc.
          </StyledSuperScript>
        </form>
      </StyledFormula>
    </StyledContainer>
  )
}

export default DsCalculatorPrompt
