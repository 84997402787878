import React from 'react'
import Helmet from 'react-helmet'
import { HelmetProvider } from 'react-helmet-async'
import url from 'url'

import { SITE_CONFIG } from '../../utils/siteConfig'
import { withLocation } from '../../utils/location'

const ImageMeta = ({ image }) => {
  if (!image) {
    return null
  }

  return (
    <Helmet>
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image' content={image} />
      <meta property='og:image' content={image} />
      <meta
        property='og:image:width'
        content={`${SITE_CONFIG.shareImageWidth}`}
      />
      <meta
        property='og:image:height'
        content={`${SITE_CONFIG.shareImageHeight}`}
      />
    </Helmet>
  )
}

const BaseMeta = ({ title, description, image, location: { location } }) => {
  const canonical = url.resolve(SITE_CONFIG.siteUrl, location.pathname)
  const openGraphType = 'website'

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <html lang={SITE_CONFIG.siteLanguageMeta} />
          <meta charSet='utf-8' />
          <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
          <meta name='viewport' content='width=device-width, initial-scale=1.0' />
          <meta name='application-name' content={SITE_CONFIG.siteTitleMeta} />
          {SITE_CONFIG.appleTouchIcons.map(({ sizes, path }) => (
            <link
              key={`apple-touch-icon-precomposed-${sizes}`}
              rel='apple-touch-icon-precomposed'
              sizes={sizes}
              href={path}
            />
          ))}
          {SITE_CONFIG.favicons.map(({ sizes, path }) => (
            <link
              key={`favicon-${sizes}`}
              rel='icon'
              type='image/png'
              sizes={sizes}
              href={path}
            />
          ))}
          <meta name='msapplication-TileColor' content={SITE_CONFIG.tileColor} />
          <meta
            name='msapplication-TileImage'
            content='/images/mstile-144x144.png'
          />
          <meta
            name='msapplication-square70x70logo'
            content='/images/mstile-70x70.png'
          />
          <meta
            name='msapplication-square150x150logo'
            content='/images/mstile-150x150.png'
          />
          <meta
            name='msapplication-wide310x150logo'
            content='/images/mstile-310x150.png'
          />
          <meta
            name='msapplication-square310x310logo'
            content='/images/mstile-310x310.png'
          />

          {/* Page Meta */}
          <title>{title}</title>
          <meta name='description' content={description} />
          <link rel='canonical' href={canonical} />

          <meta
            name='google-site-verification'
            content='TZB0CK0qYKk4Effi3SbiGz86dZSwbzVqWdbq2d4UBes'
          />

          {/* Schema */}
          <meta itemProp='name' content={title} />
          <meta itemProp='description' content={description} />

          {/* Open Graph */}
          <meta
            name='twitter:site'
            content={`https://twitter.com/${SITE_CONFIG.twitterHandle.replace(
              /^@/,
              ''
            )}/`}
          />
          <meta name='twitter:creator' content={SITE_CONFIG.twitterHandle} />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:description' content={description} />
          <meta name='twitter:url' content={canonical} />

          <meta property='og:site_name' content={SITE_CONFIG.siteTitleMeta} />
          <meta property='og:type' content={openGraphType} />
          <meta property='og:title' content={title} />
          <meta property='og:description' content={description} />
          <meta property='og:url' content={canonical} />

          {/* One Trust */}

          <script
            src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
            type='text/javascript'
            charSet='UTF-8'
            data-domain-script='5dc95b81-11bb-47b1-8a97-f199ed200a3a'
          />
          <script type='text/javascript'>{`function OptanonWrapper() {}`}</script>

          {/* GTM */}
          <script async>
            {`
          (function(w,d,s,l,i){
            w[l] = w[l] || [];
            w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j,f);
          })(window, document, 'script', 'dataLayer', 'GTM-MB28X6B');
          `}
          </script>
        </Helmet>
      </HelmetProvider>
      <ImageMeta image={image} />
    </>
  )
}

export const Meta = withLocation(BaseMeta)
