import React from 'react'
import styled from '@emotion/styled'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { mq, theme } from '../../../theme'

export type SrcSet = Array<{
  maxWidth?: number
  minWidth?: number
  resolution: number
  retina: number
}>

export type DsRetinaImageProps = {
  src: string
  srcMobile?: string
  alt?: string
  width?: string[] | string
  height?: string[] | string
  isCover?: boolean
  isRounded?: boolean
  className?: string
  srcSets?: SrcSet
}

const PlainImage = styled.img(({ isCover, height, width, isRounded }: any) =>
  mq({
    width: width,
    height: height,
    objectFit: isCover ? 'cover' : 'contain',
    borderRadius: isRounded ? '50%' : '0'
  })
)

const StyledPicture = styled.picture(() =>
  mq({
    display: 'flex'
  })
)

const getCloudinarySrcSet = (src: string, srcSets: SrcSet) => {
  const cloudinarySrc = src?.split('/upload')
  if (!cloudinarySrc || cloudinarySrc.length <= 1) {
    return null
  }
  return srcSets.map(({ minWidth, maxWidth, resolution, retina }, index) => {
    const normalSize = `${cloudinarySrc[0]}/upload/w_${resolution},q_auto${cloudinarySrc[1]}`
    const retinaSize = `${cloudinarySrc[0]}/upload/w_${retina},q_auto${cloudinarySrc[1]} 2x`
    return (
      <source
        key={`rerina-image-${index}`}
        media={
          maxWidth ? `(max-width: ${maxWidth}px)` : `(min-width: ${minWidth}px)`
        }
        srcSet={`${normalSize}, ${retinaSize}`}
      />
    )
  })
}

const getCloudinaryFallbackSrc = (src: string, srcSets?: SrcSet) => {
  const cloudinarySrc = src?.split('/upload')
  if (!cloudinarySrc || cloudinarySrc.length <= 1) {
    return src
  }
  const fallbackRes =
    (srcSets && srcSets[srcSets.length - 1].resolution) || 1200
  return `${cloudinarySrc[0]}/upload/w_${fallbackRes + 1},q_auto${
    cloudinarySrc[1]
  }`
}

export const DsRetinaImage = ({
  src,
  alt = '',
  isCover,
  srcMobile,
  width = '100%',
  height = 'auto',
  className,
  isRounded,
  srcSets
}: DsRetinaImageProps) => {
  return (
    <LazyLoadComponent>
      <StyledPicture className="ds-retina-image">
        {srcSets &&
          getCloudinarySrcSet(
            src,
            srcSets.filter(
              ({ maxWidth, minWidth }) =>
                !srcMobile ||
                (maxWidth &&
                  maxWidth >= parseFloat(theme.mediaQuery.desktop)) ||
                (minWidth && minWidth >= parseFloat(theme.mediaQuery.desktop))
            )
          )}
        {srcMobile &&
          srcSets &&
          getCloudinarySrcSet(
            srcMobile,
            srcSets.filter(
              ({ maxWidth, minWidth }) =>
                (maxWidth && maxWidth < parseFloat(theme.mediaQuery.desktop)) ||
                (minWidth && minWidth < parseFloat(theme.mediaQuery.desktop))
            )
          )}
        <PlainImage
          className={className}
          src={getCloudinaryFallbackSrc(src, srcSets)}
          alt={alt}
          isCover={isCover}
          height={height}
          width={width}
          isRounded={isRounded}
        />
      </StyledPicture>
    </LazyLoadComponent>
  )
}

export default DsRetinaImage
