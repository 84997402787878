import React from 'react'
import styled from '@emotion/styled'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { mq, theme } from '../../../theme'

export type DsImageProps = {
  src: string
  srcMobile?: string
  alt?: string
  width?: string[] | string
  height?: string[] | string
  isCover?: boolean
  isRounded?: boolean
  className?: string
}

const PlainImage = styled.img(({ isCover, height, width, isRounded }: any) =>
  mq({
    width: width,
    height: height,
    objectFit: isCover ? 'cover' : 'contain',
    borderRadius: isRounded ? '50%' : '0'
  })
)

const StyledPicture = styled.picture(() =>
  mq({
    display: 'flex'
  })
)

export const DsImage = ({
  src = '',
  alt = '',
  isCover,
  srcMobile,
  width = '100%',
  height = 'auto',
  className,
  isRounded
}: DsImageProps) => {
  return (
    <LazyLoadComponent>
      <StyledPicture>
        <source
          media={`(max-width: ${theme.mediaQuery.desktop})`}
          srcSet={srcMobile}
        />
        <PlainImage
          className={className}
          src={src}
          alt={alt}
          isCover={isCover}
          height={height}
          width={width}
          isRounded={isRounded}
        />
      </StyledPicture>
    </LazyLoadComponent>
  )
}

export default DsImage
