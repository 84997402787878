import React from 'react'
import { DsMediaCard } from '@rangleio/ds'
import { Link as GatsbyLink } from 'gatsby'

export const MediaCard = ({
  alt,
  backgroundColor,
  description,
  expandedImage,
  heading,
  image = [],
  label,
  url,
}) => {
  return (
    <DsMediaCard
      alt={alt}
      backgroundColor={backgroundColor}
      description={description}
      frameworkLinkTag={GatsbyLink}
      heading={heading}
      expandedImage={expandedImage}
      label={label}
      src={image[0]?.original_secure_url}
      url={url}
    />
  )
}

export default MediaCard
