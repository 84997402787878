import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'

export type DsMediaCardGridProps = {
  columnRatio: string
  cards: React.ReactNode[]
}

const StyledDsMediaCardGrid = styled.div(({ columnRatio }: any) =>
  mq({
    display: 'grid',
    gridTemplateColumns: [
      '1fr',
      '1fr',
      `${columnRatio.split(':').join('fr ')}fr`
    ],
    gap: theme.spacing.small,
    justifyContent: 'center'
  })
)

const StyledDsMediaCardGridItem = styled.div(() =>
  mq({
    display: 'flex'
  })
)

export const DsMediaCardGrid = ({
  columnRatio = '1:1:1',
  cards = []
}: DsMediaCardGridProps) => {
  return (
    <StyledDsMediaCardGrid columnRatio={columnRatio} className="ds-media-card-grid">
      {cards.map((card, index) => (
        <StyledDsMediaCardGridItem key={`Grid-Item-${index}`}>
          {card}
        </StyledDsMediaCardGridItem>
      ))}
    </StyledDsMediaCardGrid>
  )
}

export default DsMediaCardGrid