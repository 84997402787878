import 'normalize.css/normalize.css';
import React from 'react';
import { RecoilRoot } from 'recoil';
import { buildComponent } from './buildComponent';

const DynamicPage = ({
  pageContext: {
    buildContext: { componentTree },
  },
}) => {
  if (componentTree) {
    return <RecoilRoot>{buildComponent(componentTree)}</RecoilRoot>;
  }
  console.log('DynamicPage data error!');
  console.log('DynamicPage data error: ', JSON.stringify(componentTree));
  return false;
};

export default DynamicPage;
