import { atom, selector } from 'recoil'

// this atom holds the shared state values for each
// input that every calculator component has, as well
// as the default values for each
const calcState = atom({
  key: 'calcState',
  default: {
    prompt: {
      budget: 1000000,
      cost: 7000000,
      revenue: 10000000,
      ds: 20
    },
    remediation: {
      staff: 850,
      salary: 120000,
      benefits: 1.5,
      work: 1.5
    },
    refresh: {
      work: 1.5
    },
    potential: {
      delivery: 12,
      improvement: 80,
      experiments: 52,
      impact: 1,
      size: 10000000
    },
    throughput: {
      wait: 90,
      reduction: 20,
      delivered: 20,
      average: 30,
      value: 500000
    },
    total: {
      costs1: 125000,
      costs2: 150000,
      costs3: 350000,
      costs4: 750000
    }
  }
})

const remediateResultState = selector({
  key: 'remediateResultState',
  get: ({ get }) => {
    const remediate = get(calcState)

    const total =
      remediate.remediation.staff *
      remediate.remediation.salary *
      remediate.remediation.benefits *
      remediate.remediation.work *
      0.01

    const newTotal = total * 0.95

    const savings = total * 0.05

    return {
      total: total,
      newTotal: newTotal,
      savings: savings
    }
  }
})

const refreshResultState = selector({
  key: 'refreshResultState',
  get: ({ get }) => {
    const refresh = get(calcState)

    const total =
      refresh.remediation.staff *
      refresh.remediation.salary *
      refresh.remediation.benefits *
      refresh.refresh.work *
      0.01

    const newTotal = total * 0.95

    const savings = total * 0.05

    return {
      total: total,
      newTotal: newTotal,
      savings: savings
    }
  }
})

const potentialResultState = selector({
  key: 'potentialResultState',
  get: ({ get }) => {
    const potential = get(calcState)

    const newDelivery =
      potential.potential.delivery * potential.potential.improvement * 0.01

    const timeSaved =
      potential.potential.delivery *
      (100 - potential.potential.improvement) *
      0.01

    const potentialValue =
      potential.potential.experiments *
      0.33 *
      potential.potential.impact *
      0.01 *
      potential.potential.size

    const newInitiatives =
      potential.potential.improvement > 0
        ? potential.potential.experiments /
            (potential.potential.improvement * 0.01) -
          potential.potential.experiments
        : 0

    const potentialRevenue =
      potentialValue > 0
        ? (potentialValue / potential.potential.experiments) * newInitiatives
        : 0

    return {
      newDelivery: newDelivery,
      timeSaved: timeSaved,
      potentialValue: potentialValue,
      potentialRevenue: potentialRevenue,
      newInitiatives: newInitiatives
    }
  }
})

const throughputResultState = selector({
  key: 'throughputResultState',
  get: ({ get }) => {
    const throughput = get(calcState)

    const newWait =
      throughput.throughput.wait -
      throughput.throughput.wait * throughput.throughput.reduction * 0.01

    const newFlow = newWait + (100 - throughput.throughput.wait)

    const newDelivery = throughput.throughput.average * newFlow * 0.01

    const throughputIncrease = 100 * (100 / newFlow) - 100

    const throughputRate =
      throughput.throughput.delivered / throughput.throughput.average

    const newTPR = newFlow > 0 ? throughputRate / (newFlow * 0.01) : 0

    const throughputRevenue =
      throughputRate > 0 && throughput.throughput.average > 0
        ? throughput.throughput.delivered *
          throughput.throughput.value *
          throughputRate
        : 0

    const newRevenue =
      throughputRate > 0 && throughput.throughput.average > 0
        ? throughput.throughput.delivered * throughput.throughput.value * newTPR
        : 0

    const additionalRevenue = newRevenue - throughputRevenue

    const totalDays =
      throughput.throughput.delivered * throughput.throughput.average

    const newTotalDays = throughput.throughput.delivered * newDelivery

    const daysDiff = totalDays - newTotalDays

    return {
      newFlow: newFlow,
      newTPR: newTPR,
      newDelivery: newDelivery,
      throughputRate: throughputRate,
      throughputIncrease: throughputIncrease,
      daysDiff: daysDiff,
      additionalRevenue: additionalRevenue
    }
  }
})

const promptResultState = selector({
  key: 'promptResultState',
  get: ({ get }) => {
    const prompt = get(calcState)

    const savings =
      (parseFloat(prompt.prompt.cost) * 3 -
        parseFloat(prompt.prompt.cost) * 3 * (100 - prompt.prompt.ds) * 0.01) *
      0.01

    const value =
      0.015 * 52 * 20 * 0.33 * 0.01 * parseFloat(prompt.prompt.revenue)

    const payback = prompt.prompt.budget / (value + savings)

    return {
      savings: savings,
      payback: payback,
      value: value
    }
  }
})

const totalResultState = selector({
  key: 'totalResultState',
  get: ({ get }) => {
    const total = get(calcState)
    const remediate = get(remediateResultState)
    const refresh = get(refreshResultState)
    const potential = get(potentialResultState)
    const through = get(throughputResultState)

    const costs =
      parseFloat(total.total.costs1) +
      parseFloat(total.total.costs2) +
      parseFloat(total.total.costs3) +
      parseFloat(total.total.costs4)

    const remSavings =
      parseFloat(remediate.savings) + parseFloat(refresh.savings)

    const potSavings = parseFloat(potential.potentialRevenue)

    const tpSavings = parseFloat(through.additionalRevenue)

    const totalValue = remSavings + potSavings + tpSavings

    const roi =
      totalValue > 0 && costs > 0 ? ((totalValue - costs) / costs) * 100 : 0

    const payback = totalValue > 0 && costs > 0 ? costs / totalValue : 0

    return {
      remSavings: remSavings,
      potSavings: potSavings,
      tpSavings: tpSavings,
      total: totalValue,
      roi: roi,
      payback: payback
    }
  }
})

export {
  calcState,
  remediateResultState,
  potentialResultState,
  throughputResultState,
  totalResultState,
  promptResultState,
  refreshResultState
}
