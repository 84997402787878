import React from 'react';
import pascalCase from 'pascal-case';
import * as dsComponents from '@rangleio/ds';
import * as templates from '../templates';
import * as cmsComponents from '../components';

const components = {
  ...dsComponents,
  ...templates,
  ...cmsComponents,
};
const Fallback = (props) => <div {...props} />;
export const componentSelector = (
  componentsDictionary,
  defaultSelection = Fallback,
) => (componentType) =>
  componentsDictionary[pascalCase(componentType)] ||
  componentsDictionary[`Ds${pascalCase(componentType)}`] ||
  defaultSelection;
function hasSubComponent(val) {
  return Array.isArray(val)
    ? !!val.find((content) => !!content.componentType)
    : !!val.componentType;
}
export const buildComponent = (spec, buildContext = {}) => {
  const Component = componentSelector(components)(spec.componentType);
  const props = { ...spec.props };
  if (Component !== Fallback) {
    props.entryId = spec.entryId;
  }
  Object.keys(props).forEach((field) => {
    const val = props[field];
    if (hasSubComponent(val)) {
      props[field] = Array.isArray(val)
        ? val.map((sub) =>
            hasSubComponent(sub) ? buildComponent(sub, buildContext) : sub,
          )
        : buildComponent(val, buildContext);
    }
  });
  return (
    <Component
      key={spec.entryId}
      buildContext={buildContext}
      componentType={spec.componentType}
      {...props}
    />
  );
};
