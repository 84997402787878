import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'

export type DsChapterProps = {
  title: string
  slug: string
  chapterContent: string
}

const StyledContainer = styled.div(() =>
  mq({
    display: 'relative'
  })
)

export const DsChapter = ({
  title = '',
  slug = '',
  chapterContent = ''
}: DsChapterProps) => {
  return (
    <>
      <h2>{title}</h2>
      <StyledContainer id={slug}>{chapterContent}</StyledContainer>
    </>
  )
}

export default DsChapter
