import React, { useEffect } from 'react'
import { withNavState } from 'utils'

import { Meta } from './helmets/Meta'
import '../styles/global.css'

const BasePage = ({
  content,
  updateNavThemes,
  title,
  metaImage = [],
  description
}) => {
  useEffect(() => {
    updateNavThemes({ topBlockTheme: 'light', pageTheme: 'light' })
  }, [])

  return (
    <>
      <Meta title={title} image={metaImage[0]?.url} description={description} />
      <div className='radius cms-page' data-page>
        {content}
      </div>
    </>
  )
}

export const Page = withNavState(BasePage)
