import React from 'react'
import styled from '@emotion/styled'
import { DsLink } from '../../Atoms/DsLink'
import { mq, theme } from '../../../theme'
import { DsRetinaImage } from '../../Atoms/DsRetinaImage'

export interface DsHeroEditorialProps {
  alt?: string
  description: string
  frameworkLinkTag?: React.ReactNode
  heading: string
  linkLabel: string
  link: string
  src: string
  srcMobile?: string
  tagline: string
}

export const DsHeroEditorial = ({
  description = '',
  heading = '',
  linkLabel = '',
  link = '',
  src = '',
  frameworkLinkTag,
  srcMobile,
  tagline,
}: DsHeroEditorialProps) => {
  const StyledDsCTAContainer = styled.div(() =>
    mq({
      background: `${theme.colors.grayscale.darker}`,
      width: ['100%', '100%', '65%', '55%'],
      paddingBottom: ['10vw', '10vw', '10vw', '3vw'],
      paddingTop: ['10vw', '10vw', '10vw', '3vw'],
      position: 'relative',
      display: 'inline-block',
      marginTop: theme.spacing.large,
      marginBottom: theme.spacing.xLarge
    })
  )

  const StyledTagline = styled.p(() =>
    mq({
      paddingLeft: ['10vw', '10vw', '10vw', '3vw'],
      paddingRight: ['10vw', '10vw', '10vw', '3vw'],
      marginTop: 0
    })
  )

  const StyledHeading = styled.h1(() =>
    mq({
      paddingLeft: ['10vw', '10vw', '10vw', '3vw'],
      paddingRight: ['10vw', '10vw', '10vw', '3vw'],
      marginTop: 0
    })
  )

  const StyledDescription = styled.p(() =>
    mq({
      paddingLeft: ['10vw', '10vw', '10vw', '3vw'],
      paddingRight: ['10vw', '10vw', '10vw', '3vw'],
      marginTop: 0
    })
  )

  const StyledDsImageMobile = styled(DsRetinaImage)`
    ${mq({
      position: ['relative', 'relative', 'absolute'],
      objectFit: 'cover',
      display: ['block', 'block', 'none'],
      paddingBottom: theme.spacing.small
    })}
  `

  const StyledDsImageDesktop = styled(DsRetinaImage)`
    ${mq({
      zIndex: '-1',
      right: 0,
      top: 0,
      position: 'absolute',
      objectFit: ['cover', 'cover', 'cover', 'contain', 'cover'],
      objectPosition: ['initial', 'initial', 'initial', 'right top', 'initial'],
      width: '70%',
      height: '100%',
      display: ['none', 'none', 'block'],
      paddingBottom: theme.spacing.small
    })}
  `

  const StyledDsLink = styled.div`
    ${mq({
      paddingLeft: ['10vw', '10vw', '10vw', '3vw'],
      paddingRight: ['10vw', '10vw', '10vw', '3vw']
    })}
  `

  const StyledDsHeroContainer = styled.div`
    ${mq({
      position: 'relative',
      marginLeft: ['0', '0', '0', '7vw'],
      marginRight: ['0', '0', '0', '7vw']
    })}
  `

  const srcSets = [
    { maxWidth: 319, resolution: 300, retina: 600 },
    { minWidth: 320, resolution: 600, retina: 1200 },
    { minWidth: 1024, resolution: 1200, retina: 2400 }
  ]

  return (
    <StyledDsHeroContainer className='ds-hero-editorial'>
      <StyledDsImageDesktop
        src={src}
        srcMobile={srcMobile}
        srcSets={srcSets}
      />
      <StyledDsCTAContainer className='ds-bg-dark'>
        <StyledTagline className='ds-eyebrow-headline'>
          {tagline}
        </StyledTagline>
        <StyledHeading>{heading}</StyledHeading>
        <StyledDsImageMobile
          src={src}
          srcMobile={srcMobile}
          srcSets={srcSets}
        />
        <StyledDescription className='ds-large-body'>
          {description}
        </StyledDescription>
        <StyledDsLink>
          <DsLink
            label={linkLabel}
            url={link}
            variant='Primary Button'
            frameworkLinkTag={frameworkLinkTag}
          ></DsLink>
        </StyledDsLink>
      </StyledDsCTAContainer>
    </StyledDsHeroContainer>
  )
}

export default DsHeroEditorial
