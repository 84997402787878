import React from 'react'
import styled from '@emotion/styled'
import { DsImage } from '../../Atoms/DsImage'
import { mq, theme } from '../../../theme'

export interface DsLogoGridProps {
  logos: ReturnType<typeof DsImage>[]
}

const StyledDsLogoGrid = styled.div(() =>
  mq({
    display: 'grid',
    gridTemplateColumns: [
      'repeat( 2, 1fr )',
      'repeat( 2, 1fr )',
      'repeat( 2, 1fr )',
      'repeat( 4, 1fr )'
    ]
  })
)

const StyledDsLogoItem = styled.div(() =>
  mq({
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    '&:nth-last-child(1)': {
      borderBottom: 0
    },
    '&:nth-last-child(2)': {
      borderBottom: 0
    },
    '&:nth-last-child(3)': {
      borderBottom: [
        `solid 1px ${theme.colors.grayscale.light}`,
        `solid 1px ${theme.colors.grayscale.light}`,
        `solid 1px ${theme.colors.grayscale.light}`,
        0
      ]
    },
    '&:nth-last-child(4)': {
      borderBottom: [
        `solid 1px ${theme.colors.grayscale.light}`,
        `solid 1px ${theme.colors.grayscale.light}`,
        `solid 1px ${theme.colors.grayscale.light}`,
        0
      ]
    },

    '&:nth-child(2n+2)': {
      borderRight: [0, 0, 0, `solid 1px ${theme.colors.grayscale.light}`]
    },

    '&:nth-child(4n+4)': {
      borderRight: 0
    },
    borderRight: `solid 1px ${theme.colors.grayscale.light}`,
    borderBottom: `solid 1px ${theme.colors.grayscale.light}`
  })
)

export function DsLogoGrid({ logos = [] }: DsLogoGridProps) {
  return (
    <StyledDsLogoGrid className='ds-logo-grid'>
      {logos.map((logo, index) => (
        <StyledDsLogoItem key={`DsLogoGridItem-${index}`}>
          {logo}
        </StyledDsLogoItem>
      ))}
    </StyledDsLogoGrid>
  )
}

export default DsLogoGrid
