import React from 'react'
import styled from '@emotion/styled'
import { theme, mq } from '../../../theme'

export type DsLinkProps = {
  // This is always mandatory when used in a framework. It is to enable internal linking.
  frameworkLinkTag: React.ReactNode
  label: string
  url: string
  variant:
    | 'Primary Button'
    | 'Small Link'
    | 'Medium Link'
    | 'Large Link'
    | 'Inline Link'
  direction?: 'Left' | 'Right'
}

export const DsLink = ({
  label = '',
  url = '',
  variant = 'Primary Button',
  frameworkLinkTag,
  direction = 'Right'
}: DsLinkProps) => {
  const StyledDsLink = styled.a(() => {
    return mq({
      textDecoration: 'none',
      display: 'inline-block',
      ':hover': {
        '& span': {
          textDecoration: 'underline',
          padding: '0 2px',
          transform: 'translateX(5px)'
        }
      },
      '& span': {
        transition: theme.transitions.durations.fast
      }
    })
  })

  const StyledDsWrapper = styled.div(() => {
    type FontStyles = {
      fontWeight: string | Number
      lineHeight: string | Number
      color: string
      [key: string]: any
    }
    const fontStyles: FontStyles = {
      fontWeight: 500,
      lineHeight: 1.3,
      color: theme.colors.grayscale.dark,
      '.ds-bg-dark &': { color: theme.colors.grayscale.white }
    }
    switch (variant) {
      case 'Large Link':
        fontStyles['fontSize'] = [
          '1.125rem',
          '1.125rem',
          '1.25rem',
          '1.25rem',
          '1.75rem'
        ]
        break
      case 'Medium Link':
        fontStyles['fontSize'] = [
          '0.875rem',
          '1rem',
          '1.125rem',
          '1.125rem',
          '1.5rem'
        ]
        break
      case 'Small Link':
        fontStyles['fontSize'] = [
          '0.625rem',
          '0.75rem',
          '0.75rem',
          '0.75rem',
          '1.25rem'
        ]
        break
      case 'Inline Link':
        fontStyles['fontSize'] = 'inherit'
        fontStyles['fontWeight'] = 'inherit'
        fontStyles['lineHeight'] = 'inherit'
        break
      default:
        fontStyles['color'] = theme.colors.grayscale.white
        fontStyles['fontWeight'] = 400
        break
    }

    return mq({
      ...fontStyles,
      backgroundColor:
        variant === 'Primary Button'
          ? theme.colors.redscale.darker
          : 'transparent',
      padding: variant === 'Primary Button' ? theme.spacing.xSmall : 'auto',
      textDecoration:
        variant === 'Inline Link' && isExternal ? 'underline' : 'none',
      transition: theme.transitions.durations.medium,
      ':hover': {
        backgroundColor:
          variant === 'Primary Button'
            ? theme.colors.grayscale.white
            : 'transparent',
        color: theme.colors.redscale.darker
      }
    })
  })
  const isMailTo = (url: string) => /^mailto?/i.test(url)
  const isHttp = (url: string) => /^http?/i.test(url)
  const isAnchor: boolean = /^#/i.test(url)
  const isExternal: boolean = isHttp(url) || isMailTo(url)
  const CTAComponent: any =
    !frameworkLinkTag || isExternal ? 'a' : frameworkLinkTag

  const internalProps = {
    to: url.startsWith('/') ? url : `/${url}`
  }
  const externalProps = {
    href: url,
    target: isMailTo(url) ? '_self' : '_blank'
  }

  const LeftLinkArrow = () => {
    let arrow = '←'
    if (isExternal) {
      arrow = '↖'
    } else if (isAnchor) {
      arrow = '↓'
    }
    return (
      <>
        {arrow}
        <>&nbsp;</>
      </>
    )
  }

  const RightLinkArrow = () => {
    let arrow = '→'
    if (isExternal) {
      arrow = '↗'
    } else if (isAnchor) {
      arrow = '↓'
    }
    return (
      <>
        <>&nbsp;</>
        {arrow}
      </>
    )
  }

  return (
    <StyledDsLink
      as={CTAComponent}
      {...(CTAComponent === 'a' ? externalProps : internalProps)}
      data-on='click'
      data-event-category='CTA'
      data-event-action='click'
      data-event-label={url}
      className='ds-link-style'
    >
      <StyledDsWrapper>
        {direction === 'Left' ? (
          <>
            <LeftLinkArrow />
            <span></span>
            {label}
          </>
        ) : (
          <>
            {label}
            <span></span>
            <RightLinkArrow />
          </>
        )}
      </StyledDsWrapper>
    </StyledDsLink>
  )
}
export default DsLink
