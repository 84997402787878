import React from 'react'
import { DsImage, DsRetinaImage } from '@rangleio/ds'

const srcSets = [
  { minWidth: 0, resolution: 600, retina: 1200 },
  { minWidth: 768, resolution: 800, retina: 1600 },
  { minWidth: 1280, resolution: 1600, retina: 2400 }
]

export const Image = ({
  alt,
  image = [],
  mobileImage = [],
  standalone = false
}) => {
  return standalone ? (
    <DsRetinaImage
      alt={alt}
      src={image[0]?.original_secure_url}
      srcMobile={mobileImage[0]?.original_secure_url}
      srcSets={srcSets}
    />
  ) : (
    <DsImage
      alt={alt}
      src={image[0]?.original_secure_url}
      srcMobile={mobileImage[0]?.original_secure_url}
    />
  )
}

export default Image
