import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'

export type DsVideoProps = {
  src: string
  autoplay?: boolean
  muted?: boolean
}

const StyledDsContainer = styled.div(() =>
  mq({
    position: 'relative'
  })
)

const StyledVideo = styled.video(() =>
  mq({
    height: '100%',
    width: '100%',
    background: theme.colors.grayscale.dark
  })
)

const StyledDsWrapper = styled.div(() =>
  mq({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  })
)

const StyledDsInner = styled.div(() =>
  mq({
    display: 'flex',
    height: 'inherit',
    justifyContent: 'center',
    alignItems: 'center'
  })
)

const StyledDsSvg = styled.div(() =>
  mq({
    height: theme.spacing.large,
    fill: theme.colors.grayscale.white,
    cursor: 'pointer',
    transition: theme.transitions.durations.medium,
    ':hover': {
      fill: theme.colors.redscale.dark
    }
  })
)

export const DsVideo = ({
  autoplay = false,
  muted = true,
  src = ''
}: DsVideoProps) => {
  const initalRender = useRef<boolean>(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const play = () => {
    setIsPlaying(true)
    track('Play', null, autoplay)
  }
  const pause = () => {
    setIsPlaying(false)
    track('Pause', null, autoplay)
  }
  let publicId: string | number = src?.lastIndexOf('/')
  publicId = src
    .substring(publicId + 1)
    .split('.')
    .slice(0, -1)
    .join('.')

  const track = (action: string, value: any, nonInteraction: boolean) =>
    window['gtag'] &&
    window['gtag']('event', action, {
      event_category: 'Video',
      event_label: publicId,
      ...(value ? { value } : null),
      non_interaction: nonInteraction
    })

  const trackStart = () => track('Start', null, true)
  const trackTimeUpdate = () =>
    track('Time Update', videoRef.current?.currentTime.toFixed(1), true)

  const trackSeeking = () => {
    play()
    track('Seeking', videoRef.current?.currentTime.toFixed(1), true)
  }

  const trackSeeked = () => {
    track('Seek', videoRef.current?.currentTime.toFixed(1), true)
  }

  const trackEnded = () => {
    play()
    track('Ended', null, true)
  }

  const trackVolumeChange = () => {
    track('Volume Change', videoRef.current?.volume.toFixed(1), muted)
  }

  useEffect(() => {
    if (initalRender.current) {
      videoRef.current?.addEventListener('play', play)
      videoRef.current?.addEventListener('pause', pause)
      videoRef.current?.addEventListener('playing', trackStart)
      videoRef.current?.addEventListener('timeupdate', trackTimeUpdate)
      videoRef.current?.addEventListener('ended', trackEnded)
      videoRef.current?.addEventListener('seeking', trackSeeking)
      videoRef.current?.addEventListener('volumechange', trackVolumeChange)
      videoRef.current?.addEventListener('seeked', trackSeeked)
      initalRender.current = false
    }
    return () => {
      videoRef.current?.removeEventListener('play', play)
      videoRef.current?.removeEventListener('pause', pause)
      videoRef.current?.removeEventListener('playing', trackStart)
      videoRef.current?.removeEventListener('timeupdate', trackTimeUpdate)
      videoRef.current?.removeEventListener('seeking', trackSeeking)
      videoRef.current?.removeEventListener('seeked', trackSeeked)
      videoRef.current?.removeEventListener('ended', trackEnded)
      videoRef.current?.removeEventListener('volumechange', trackVolumeChange)
    }
  }, [])

  const SvgPlayIcon = (props: any) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 58.752 58.752'
      style={{
        enableBackground: 'new 0 0 58.752 58.752'
      }}
      xmlSpace='preserve'
      {...props}
    >
      <path d='M52.524 23.925 12.507.824c-1.907-1.1-4.376-1.097-6.276 0a6.294 6.294 0 0 0-3.143 5.44v46.205a6.29 6.29 0 0 0 3.131 5.435 6.263 6.263 0 0 0 6.29.005l40.017-23.103a6.3 6.3 0 0 0 3.138-5.439 6.315 6.315 0 0 0-3.14-5.442zm-3 5.687L9.504 52.716a.27.27 0 0 1-.279-.005.28.28 0 0 1-.137-.242V6.263a.28.28 0 0 1 .421-.243l40.01 23.098a.29.29 0 0 1 .145.249.283.283 0 0 1-.14.245z' />
    </svg>
  )
  return (
    <StyledDsContainer className='ds-video'>
      <StyledVideo
        ref={videoRef}
        controls={isPlaying}
        preload='auto'
        autoPlay={autoplay}
        muted={muted}
        src={src.substring(0, src.lastIndexOf('.')) + '.mov'}
        webkit-playsinline
        playsInline
      />

      {!isPlaying && (
        <StyledDsWrapper>
          <StyledDsInner>
            <StyledDsSvg onClick={() => videoRef.current?.play()}>
              <SvgPlayIcon fill='inherit' height='100%' />
            </StyledDsSvg>
          </StyledDsInner>
        </StyledDsWrapper>
      )}
    </StyledDsContainer>
  )
}

export default DsVideo
