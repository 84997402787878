import React from 'react'
import styled from '@emotion/styled'
import { theme, mq } from '../../../theme'
import { DsLink } from '../../Atoms/DsLink'

export interface DsNextChapterProps {
  ctaHeading?: string
  ctaLinkText?: string
  ctaSlug?: string
  frameworkLinkTag?: React.ReactNode
  navigationHeading?: string
  navigationLinkText?: string
  navigationSlug?: string
  hubPage?: boolean
}

export function DsNextChapter({
  ctaHeading = '',
  ctaLinkText = '',
  ctaSlug = '',
  frameworkLinkTag = '',
  navigationHeading = '',
  navigationLinkText = '',
  navigationSlug = '',
  hubPage = false
}: DsNextChapterProps) {
  const StyledDsGrid = styled.div(() =>
    mq({
      display: 'grid',
      minHeight: theme.spacing.xLarge,
      gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
      backgroundColor: theme.colors.grayscale.darker,
      gap: '1px'
    })
  )

  const StyledDsItem = styled.div(() =>
    mq({
      backgroundColor: theme.colors.grayscale.white,
      padding: `0 ${theme.spacing.large}`
    })
  )

  const StyledDsItemWrapper = styled.div(() =>
    mq({
      margin: theme.spacing.large
    })
  )

  return (
    <div className='ds-next-chapter'>
      <StyledDsGrid>
        <StyledDsItem>
          {ctaHeading && (
            <StyledDsItemWrapper>
              <h2>{ctaHeading}</h2>
              <DsLink
                label={ctaLinkText}
                url={ctaSlug}
                variant='Large Link'
                frameworkLinkTag={frameworkLinkTag}
                direction={hubPage ? 'Left' : 'Right'}
              ></DsLink>
            </StyledDsItemWrapper>
          )}
        </StyledDsItem>
        <StyledDsItem>
          {navigationHeading && (
            <StyledDsItemWrapper>
              <h2>{navigationHeading}</h2>
              <DsLink
                label={navigationLinkText}
                url={navigationSlug}
                variant='Large Link'
                frameworkLinkTag={frameworkLinkTag}
              ></DsLink>
            </StyledDsItemWrapper>
          )}
        </StyledDsItem>
      </StyledDsGrid>
    </div>
  )
}

export default DsNextChapter
