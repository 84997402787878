import React from 'react'
import { DsAccordion } from '@rangleio/ds'

export const Accordion = ({
  accordionItems,
}) => {
  const items = accordionItems.map((item) => item.props)
  return (
    <DsAccordion
      accordionItems={items}
    />
  )
}

export default Accordion
