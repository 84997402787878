import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'
import { useRecoilState, useRecoilValue } from 'recoil'
import { calcState, totalResultState } from '../../../state'
import CurrencyFormat from 'react-currency-format'

export interface CalculatorTotalProps {
  name?: string
}

const StyledContainer = styled.div(() =>
  mq({
    marginBottom: theme.spacing.small,
    marginTop: theme.spacing.medium,
    width: ['100%', '100%', '650px']
  })
)

const StyledPair = styled.div(() =>
  mq({
    display: ['block', 'block', 'flex'],
    justifyContent: ['initial', 'initial', 'space-between']
  })
)

const StyledLabel = styled.label(() =>
  mq({
    fontWeight: '200 !important',
    paddingTop: '12px',
    width: ['300px', '300px', '70%']
  })
)

const StyledNum = styled.div(() =>
  mq({
    fontWeight: '200 !important',
    paddingBottom: ['12px', '12px', '0px'],
    paddingTop: ['0px', '0px', '12px']
  })
)

const StyledInput = styled.input(() =>
  mq({
    background: theme.colors.grayscale.darker,
    border: `1px solid ${theme.colors.grayscale.lighter}`,
    color: theme.colors.grayscale.lighter,
    height: '20px',
    justifyContent: ['start', 'start', 'right'],
    marginBottom: theme.spacing.xxSmall,
    marginTop: theme.spacing.xxSmall,
    paddingBottom: '12px',
    paddingTop: '12px',
    textAlign: 'right',
    width: ['95%', '95%', '150px']
  })
)

const StyledFormula = styled.div(() =>
  mq({
    boxShadow: `40px -20px ${theme.colors.grayscale.dark}`,
    backgroundColor: theme.colors.grayscale.darker,
    color: theme.colors.grayscale.light,
    display: ['block', 'block', 'inline-block'],
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing.small,
    width: ['100%', '100%', '450px', '700px', '850px']
  })
)

const StyledSuperScript = styled.div(() =>
  mq({
    color: `${theme.colors.grayscale.light} !important`,
    marginBottom: ['8px !important', '8px !important', '0px !important'],
    marginTop: ['-5px !important', '-5px !important', '-19px !important'],
    paddingBottom: '0',
    paddingTop: '0',
    width: ['100%', '100%', '70%']
  })
)

export function CalculatorTotal({}: CalculatorTotalProps) {
  interface Inputs {
    name: string
    placeholder: string
  }

  // function for the input boxes; as the input changes, it will update
  // the state values
  function CalcInput(props: Inputs) {
    const [total, setTotal] = useRecoilState(calcState)

    const formChange = (event: any) => {
      if (!isNaN(event.target.value)) {
        setTotal((currentState: any) => ({
          ...currentState,
          total: {
            ...currentState.total,
            [event.target.name]: event.target.value
          }
        }))
      }
    }

    return (
      <StyledInput
        name={props.name}
        placeholder={props.placeholder}
        onChange={(e) => formChange(e)}
        autoComplete='off'
      />
    )
  }

  // this is the output part of the calculator;
  // uses the helper function in the state.js file that's
  // related to this calculator and gets the recoilvalue
  function CalcOutput() {
    const result = useRecoilValue(totalResultState)

    return (
      <>
        <br />
        <h2 style={{ color: theme.colors.grayscale.light }}>Estimated ROI</h2>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>ROI (%):</StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.roi}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              suffix={'%'}
            />
          </StyledNum>
        </StyledPair>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            Payback Period (Years):
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.payback}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </StyledNum>
        </StyledPair>
        <StyledSuperScript className='ds-small-body'>
          Assuming that the revenue gained per year remains consistent
        </StyledSuperScript>
      </>
    )
  }

  function CalcOutput2() {
    const result = useRecoilValue(totalResultState)

    return (
      <>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            Brand Cost Savings:
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.remSavings}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={'$'}
            />
          </StyledNum>
        </StyledPair>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            CX Revenue Gains:
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.potSavings}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={'$'}
            />
          </StyledNum>
        </StyledPair>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            EX Cost Savings:
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.tpSavings}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={'$'}
            />
          </StyledNum>
        </StyledPair>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            Estimated DS Value Gained:
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.total}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={'$'}
            />
          </StyledNum>
        </StyledPair>
      </>
    )
  }

  return (
    <>
      <StyledContainer>
        <StyledFormula>
          <h2 style={{ color: theme.colors.grayscale.light }}>
            Potential Value Gains Using Design Systems
          </h2>
          <CalcOutput2 />
          <hr />
          <form>
            <br />
            <h2 style={{ color: theme.colors.grayscale.light }}>
              Estimated Cost to Build a Design System
            </h2>
            <StyledPair>
              <StyledLabel className='ds-eyebrow-headline'>
                Phase 1 Audit:
              </StyledLabel>
              <CalcInput name='costs1' placeholder='125000' />
            </StyledPair>
            <StyledPair>
              <StyledLabel className='ds-eyebrow-headline'>
                Phase 2 POC:
              </StyledLabel>
              <CalcInput name='costs2' placeholder='150000' />
            </StyledPair>
            <StyledPair>
              <StyledLabel className='ds-eyebrow-headline'>
                Phase 3 Core DS:
              </StyledLabel>
              <CalcInput name='costs3' placeholder='350000' />
            </StyledPair>
            <StyledPair>
              <StyledLabel className='ds-eyebrow-headline'>
                Phase 4 Scaled DS:
              </StyledLabel>
              <CalcInput name='costs4' placeholder='750000' />
            </StyledPair>
          </form>
          <hr />
          <CalcOutput />
        </StyledFormula>
      </StyledContainer>
    </>
  )
}

export default CalculatorTotal
