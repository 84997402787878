import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'
import { useRecoilState, useRecoilValue } from 'recoil'
import { calcState, throughputResultState } from '../../../state'
import CurrencyFormat from 'react-currency-format'

export interface DsCalculatorThroughputProps {
  name?: string
}

const StyledContainer = styled.div(() =>
  mq({
    marginBottom: theme.spacing.small,
    marginTop: theme.spacing.medium,
    width: ['100%', '100%', '650px']
  })
)

const StyledPair = styled.div(() =>
  mq({
    display: ['block', 'block', 'flex'],
    justifyContent: ['initial', 'initial', 'space-between']
  })
)

const StyledLabel = styled.label(() =>
  mq({
    fontWeight: '200 !important',
    paddingTop: '12px',
    width: ['300px', '300px', '70%']
  })
)

const StyledNum = styled.div(() =>
  mq({
    fontWeight: '200 !important',
    paddingBottom: ['12px', '12px', '0px'],
    paddingTop: ['0px', '0px', '12px']
  })
)

const StyledInput = styled.input(() =>
  mq({
    background: theme.colors.grayscale.darker,
    border: `1px solid ${theme.colors.grayscale.lighter}`,
    color: theme.colors.grayscale.lighter,
    height: '20px',
    justifyContent: ['start', 'start', 'right'],
    marginBottom: theme.spacing.xxSmall,
    marginTop: theme.spacing.xxSmall,
    paddingBottom: '12px',
    paddingTop: '12px',
    textAlign: 'right',
    width: ['95%', '95%', '150px']
  })
)

const StyledFormula = styled.div(() =>
  mq({
    boxShadow: `40px -20px ${theme.colors.grayscale.dark}`,
    backgroundColor: theme.colors.grayscale.darker,
    color: theme.colors.grayscale.light,
    display: ['block', 'block', 'inline-block'],
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing.small,
    width: ['100%', '100%', '450px', '700px', '850px']
  })
)

const StyledSuperScript = styled.div(() =>
  mq({
    color: `${theme.colors.grayscale.light} !important`,
    marginBottom: ['8px !important', '8px !important', '0px !important'],
    marginTop: ['-5px !important', '-5px !important', '-19px !important'],
    paddingBottom: '0',
    paddingTop: '0',
    width: ['100%', '100%', '70%']
  })
)

export function DsCalculatorThroughput({}: DsCalculatorThroughputProps) {
  interface Inputs {
    name: string
    placeholder: string
  }

  // function for the input boxes; as the input changes, it will update
  // the state values
  function CalcInput(props: Inputs) {
    const [throughput, setThroughput] = useRecoilState(calcState)

    const formChange = (event: any) => {
      if (!isNaN(event.target.value)) {
        setThroughput((currentState: any) => ({
          ...currentState,
          throughput: {
            ...currentState.throughput,
            [event.target.name]: event.target.value
          }
        }))
      }
    }

    return (
      <StyledInput
        name={props.name}
        placeholder={props.placeholder}
        onChange={(e) => formChange(e)}
        autoComplete='off'
      />
    )
  }

  // this is the output part of the calculator;
  // uses the helper function in the state.js file that's
  // related to this calculator and gets the recoilvalue
  function CalcOutput() {
    const result = useRecoilValue(throughputResultState)

    return (
      <>
        <br />
        <h2 style={{ color: theme.colors.grayscale.light }}>
          Potential Benefits
        </h2>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            Throughput Improvement (%):
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.throughputIncrease}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              suffix={'%'}
            />
          </StyledNum>
        </StyledPair>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            Production Days Saved:
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.daysDiff}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </StyledNum>
        </StyledPair>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            Financial Throughput Increase ($):
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.additionalRevenue}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={'$'}
            />
          </StyledNum>
        </StyledPair>
      </>
    )
  }

  // function CalcOutput2() {
  //   const result = useRecoilValue(throughputResultState)

  //   return (
  //     <>
  //       <StyledPair>
  //         <StyledLabel className='ds-eyebrow-headline'>
  //           New Flow Time (%):
  //         </StyledLabel>
  //         <StyledNum>
  //           <CurrencyFormat
  //             value={result.newFlow}
  //             decimalScale={2}
  //             displayType={'text'}
  //             fixedDecimalScale={true}
  //             thousandSeparator={true}
  //             suffix={'%'}
  //           />
  //         </StyledNum>
  //       </StyledPair>
  //     </>
  //   )
  // }

  function CalcOutput3() {
    const result = useRecoilValue(throughputResultState)

    return (
      <StyledPair>
        <StyledLabel className='ds-eyebrow-headline'>
          Your Organizational Throughput Rate:
        </StyledLabel>
        <StyledNum>
          <CurrencyFormat
            value={result.throughputRate * 100}
            decimalScale={2}
            displayType={'text'}
            fixedDecimalScale={true}
            thousandSeparator={true}
            suffix={'%'}
          />
        </StyledNum>
      </StyledPair>
    )
  }

  return (
    <>
      <StyledContainer>
        <StyledFormula>
          <h2 style={{ color: theme.colors.grayscale.light }}>
            Your Organization Today
          </h2>
          <form>
            <StyledPair>
              <StyledLabel className='ds-eyebrow-headline'>
                Estimated Number of Projects Your Digital Team Delivered Last
                Year:
              </StyledLabel>
              <CalcInput name='delivered' placeholder='20' />
            </StyledPair>
            <StyledPair>
              <StyledLabel className='ds-eyebrow-headline'>
                Average Project Delivery Time (Days):
              </StyledLabel>
              <CalcInput name='average' placeholder='30' />
            </StyledPair>
            <StyledPair>
              <StyledLabel className='ds-eyebrow-headline'>
                Average Value per Project ($):
              </StyledLabel>
              <CalcInput name='value' placeholder='500000' />
            </StyledPair>
            <CalcOutput3 />
            <hr />
            <br />
            <h2 style={{ color: theme.colors.grayscale.light }}>
              Design System Multiplier
            </h2>
            <StyledPair>
              <StyledLabel className='ds-eyebrow-headline'>
                Design Systems Efficiency Improvement (%):
              </StyledLabel>
              <CalcInput name='reduction' placeholder='20' />
            </StyledPair>
            <StyledSuperScript className='ds-small-body'>
              Our default value assumes Design Systems increase workflow
              efficiency by 20%. You can edit the default to any number you
              choose.
            </StyledSuperScript>
            <br />
          </form>
          <hr />
          <CalcOutput />
        </StyledFormula>
      </StyledContainer>
    </>
  )
}

export default DsCalculatorThroughput
