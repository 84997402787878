import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'

export type DsGridProps = {
  columnRatio: string
  gridItems: React.ReactNode[]
}

const StyledDsGrid = styled.div(({ columnRatio }: any) =>
  mq({
    display: 'grid',
    gridTemplateColumns: [
      '1fr',
      '1fr',
      `${columnRatio.split(':').join('fr ')}fr`
    ],
    gap: [0, 0, theme.spacing.small, theme.spacing.small],
    justifyContent: 'center'
  })
)

const StyledDsGridItem = styled.div(() =>
  mq({
    display: 'flex',
    flexDirection: 'column'
  })
)

export const DsGrid = ({
  columnRatio = '1:1',
  gridItems = []
}: DsGridProps) => {
  return (
    <StyledDsGrid columnRatio={columnRatio} className="ds-grid">
      {gridItems.map((gridItem, index) => (
        <StyledDsGridItem key={`Grid-Item-${index}`}>
          {gridItem}
        </StyledDsGridItem>
      ))}
    </StyledDsGrid>
  )
}

export default DsGrid