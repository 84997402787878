import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'
import { DsRichText } from '../../Molecules/DsRichText'

export type DsEditorialSectionProps = {
  backgroundColor?: 'dark' | 'light'
  content: React.ReactNode[]
  sectionHeader: string
  editorialParagraph: string
}
const StyledDsEditorialSection = styled.div(({ backgroundColor }: { backgroundColor: 'dark' | 'light' }) =>
  mq({
    backgroundColor: backgroundColor === 'dark'
      ? theme.colors.grayscale.darker
      : theme.colors.grayscale.white,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing.xLarge,
    paddingBottom: theme.spacing.xLarge
  })
);
const StyledDsEditorialHeader = styled.div(() =>
  mq({
    paddingBottom: theme.spacing.small
  })
);
export const DsEditorialSection = ({
  backgroundColor = 'light',
  content,
  editorialParagraph,
  sectionHeader
}: DsEditorialSectionProps) => {
  return (
    <StyledDsEditorialSection
      backgroundColor={backgroundColor}
      className={`ds-editorial-section ${backgroundColor === 'dark' ? 'ds-bg-dark' : ''}`}>
      <StyledDsEditorialHeader>
        <h1>{sectionHeader}</h1>
        <DsRichText content={editorialParagraph} />
      </StyledDsEditorialHeader>
      {content.map((item, index) => (
        <div key={`ds-editorial-section${index}`}>
          {item}
        </div>
      ))}
    </StyledDsEditorialSection>
  )
}
export default DsEditorialSection