import React from 'react'
import { DsCaseStudyHero } from '@rangleio/ds'

export const CaseStudyHero = ({
  alt,
  background,
  logo,
  heading,
  image,
  industry,
  services
}) => {
  return (
    <DsCaseStudyHero
      src={(image && image[0]?.original_secure_url) || ''}
      alt={alt}
      background={background}
      heading={heading}
      logo={(logo && logo[0]?.original_secure_url) || ''}
      industry={industry}
      services={services}
    />
  )
}

export default CaseStudyHero
