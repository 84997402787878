import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { calcState, promptResultState } from '../../../state'
import { useRecoilValue } from 'recoil'

export interface DsChartReturnProps {
  name?: string
}

const StyledChart = styled.div(() =>
  mq({
    display: ['block', 'block', 'inline-block'],
    verticalAlign: 'top',
    width: ['100%', '100%', '100%', '50%']
  })
)

// this plugin lets the user see the value of the line
// graphs at each Year point
const plugin = {
  id: 'corsair',
  afterInit: (chart: any) => {
    chart.corsair = {
      x: 0,
      y: 0
    }
  },
  afterEvent: (chart: any, evt: any) => {
    const {
      chartArea: { top, bottom, left, right }
    } = chart
    const { x, y } = evt.event
    if (x < left || x > right || y < top || y > bottom) {
      chart.corsair = {
        x,
        y,
        draw: false
      }
      chart.draw()
      return
    }

    chart.corsair = {
      x,
      y,
      draw: true
    }

    chart.draw()
  },
  afterDatasetsDraw: (chart: any, _: any, opts: any) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right }
    } = chart
    const { x, y, draw } = chart.corsair

    if (!draw) {
      return
    }

    ctx.lineWidth = opts.width || 0
    ctx.setLineDash(opts.dash || [])
    ctx.strokeStyle = opts.color || 'black'

    ctx.save()
    ctx.beginPath()
    if (opts.vertical) {
      ctx.moveTo(x, bottom)
      ctx.lineTo(x, top)
    }
    if (opts.horizontal) {
      ctx.moveTo(left, y)
      ctx.lineTo(right, y)
    }
    ctx.stroke()
    ctx.restore()
  }
}

// If you want to add a function/feature to the chart, you
// need to register it here
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  plugin
)

export const options = {
  responsive: true,
  scales: {
    y: {
      position: 'right' as const
    }
  },
  plugins: {
    legend: {
      position: 'bottom' as const
    },
    title: {
      display: true,
      text: 'Time to Return on Investment'
    }
  }
}

const labels = ['Year 0', 'Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5']

export function DsChartReturn() {
  const calc = useRecoilValue(calcState)
  const result = useRecoilValue(promptResultState)

  const data = {
    labels,
    datasets: [
      {
        id: '1',
        label: 'Initial Investment',
        data: labels.map(() => calc.prompt.budget),
        fill: true,
        borderColor: 'rgb(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        id: '2',
        label: 'Additional Value from DS',
        data: labels.map((_point, index) => result.value * index),
        fill: true,
        borderColor: 'rgb(53, 162, 235, 1)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  }

  return (
    <StyledChart>
      <Line options={options} data={data} />
    </StyledChart>
  )
}

export default DsChartReturn
