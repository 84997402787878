import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'

export type DsTableProps = {
  tableHeaders: string[];
  tableContent: string[][];
}

const StyledDsTableContainer = styled.div(() =>
  mq({
    overflowX: "auto",
    width: 'inherit',
  })
)

const StyledDsTable = styled.table(() =>
  mq({
    border: "1px solid",
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "fixed"
  })
)

const StyledCol = styled.col(() =>
  mq({
    minWidth: theme.spacing.xxLarge
  })
)

const StyledDsTh = styled.th(() =>
  mq({
    border: "1px solid",
    paddingTop: theme.spacing.small,
    paddingLeft: theme.spacing.small,
    paddingRight: theme.spacing.small,
    textAlign: 'start'
  })
)

const StyledDsTd = styled.td(() =>
  mq({
    border: "1px solid",
    padding: theme.spacing.small,
    textAlign: 'start'
  })
)

export const DsTable = ({
  tableHeaders,
  tableContent
}: DsTableProps) => {
  return (
    <StyledDsTableContainer className='ds-table'>
      <StyledDsTable>
        <colgroup>
          <StyledCol span={tableHeaders.length} />
        </colgroup>
        <tbody >
          <tr>
            {tableHeaders.map((header, thIndex) =>
              <StyledDsTh key={`th${thIndex}`}>
                <p>{header}</p>
              </StyledDsTh>
            )}
          </tr>
          {tableContent.map((trItem) =>
            <tr>
              {trItem.map((tdItem) =>
                <StyledDsTd >
                  <div>{tdItem}</div>
                </StyledDsTd>
              )}
            </tr>
          )}
        </tbody>
      </StyledDsTable>
    </StyledDsTableContainer>
  )
}
export default DsTable