import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'

const StyledSpacer = styled.div(() =>
  mq({
    paddingBottom: theme.spacing.xLarge
  })
)

export function DsSectionSpacer() {
  return <StyledSpacer className='ds-section-spacer'></StyledSpacer>
}

export default DsSectionSpacer
