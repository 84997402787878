import React from 'react'
import { DsHeroEditorial } from '@rangleio/ds'
import { Link as GatsbyLink } from 'gatsby';

export const HeroEditorial = ({
  image,
  alt,
  tagline,
  heading,
  description,
  link,
  linkLabel
}) => {
  return (
    <DsHeroEditorial
      src={image?.props.image[0]?.original_secure_url}
      srcMobile={
        image?.props.mobileImage
          ? image.props.mobileImage[0]?.original_secure_url
          : undefined
      }
      alt={alt}
      tagline={tagline}
      heading={heading}
      description={description}
      link={link}
      linkLabel={linkLabel}
      frameworkLinkTag={GatsbyLink}
    />
  )
}

export default HeroEditorial
