import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'
import { DsRetinaImage } from '../../Atoms/DsRetinaImage'
import { DsLink } from '../../Atoms/DsLink'

export interface DsMediaCardProps {
  alt: string
  backgroundColor?: 'dark' | 'light'
  description?: string
  expandedImage?: boolean
  frameworkLinkTag?: React.ReactNode
  heading: string
  label?: string
  src: string
  url: string
}

export function DsMediaCard({
  alt = '',
  backgroundColor = 'dark',
  description = '',
  expandedImage = false,
  frameworkLinkTag = '',
  heading = '',
  label = '',
  src = '',
  url = ''
}: DsMediaCardProps) {
  const dsLinkRef = useRef<HTMLDivElement>(null)
  const handleCardClick = () => {
    const anchor = dsLinkRef?.current?.children[0] as HTMLAnchorElement;
    anchor.click();
  }

  const StyledDsMediaCard = styled.div(() =>
    mq({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1',
      width: '100%',
      cursor: 'pointer',
      transition: theme.transitions.durations.medium,
      ':hover': {
        boxShadow: `0 0 11px ${theme.colors.grayscale.light}`,
        '* > :last-child > :last-child': {
          color: theme.colors.redscale.darker
        }
      }
    })
  )

  const StyledDsBodyContainer = styled.div(() =>
    mq({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      backgroundColor:
        backgroundColor === 'dark'
          ? theme.colors.grayscale.darker
          : theme.colors.grayscale.white,
      padding: theme.spacing.small,
      '& h3, p': {
        marginTop: 0
      }
    })
  )

  const srcSets = [
    { maxWidth: 320, resolution: 300, retina: 600 },
    { minWidth: 320, resolution: 400, retina: 800 }
  ]

  return (
    <StyledDsMediaCard
      onClick={handleCardClick}
      className={`${backgroundColor === 'dark' ? 'ds-bg-dark' : ''}`}
    >
      <DsRetinaImage
        src={src}
        alt={alt}
        isCover={true}
        height={expandedImage ? '100%' : theme.spacing.xLarge}
        srcSets={srcSets}
      ></DsRetinaImage>
      <StyledDsBodyContainer>
        <div>
          <h3>{heading}</h3>
          <p>{description}</p>
        </div>
        <div ref={dsLinkRef}>
          <DsLink
            frameworkLinkTag={frameworkLinkTag}
            label={label}
            variant='Medium Link'
            url={url}
          />
        </div>
      </StyledDsBodyContainer>
    </StyledDsMediaCard>
  )
}

export default DsMediaCard
