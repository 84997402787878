import React from 'react'
import { DsQuote } from '@rangleio/ds'

export const Quote = ({ attribution, image, quote }) => (
  <DsQuote
    attribution={attribution}
    quote={quote}
    src={image && image[0]?.original_secure_url}
  />
)

export default Quote
