import React from 'react'
import styled from '@emotion/styled'
import { DsImage } from '../../Atoms/DsImage'
import { mq, theme } from '../../../theme'

export interface DsQuoteProps {
  attribution: string
  quote: string
  src?: string
}

const StyledFigure = styled.figure(() =>
  mq({
    margin: 0
  })
)

const StyledBlockquote = styled.blockquote(() =>
  mq({
    margin: 0,
    quotes: `'“' '”' '‘' '’'`,
    position: 'relative',

    '&:before': {
      backgroundColor: theme.colors.redscale.darker,
      content: '""',
      height: ['1.5rem', '1.5rem', '2rem'],
      left: [`-${theme.spacing.medium}`, '-2rem', '-2.5rem', '-8rem'],
      position: 'absolute',
      top: [0, 0, 0, '0.45rem'],
      width: ['0.5rem', '0.5rem', '0.5rem', '4.5rem']
    }
  })
)

const StyledParagraph = styled.p(() =>
  mq({
    marginBottom: theme.spacing.xSmall,
    marginTop: 0,
    position: 'relative',

    '&::before': {
      content: 'open-quote',
      position: 'absolute',
      left: ['-.75rem', '-.75rem', '-1rem', '-1.25rem']
    },

    '&::after': {
      content: 'close-quote'
    }
  })
)

const StyledImageContainer = styled.div(() =>
  mq({
    marginBottom: theme.spacing.xSmall,
    width: ['4rem', '4rem', '6rem', '8.5rem']
  })
)

const StyledCite = styled.cite(() =>
  mq({
    display: 'block',
    fontStyle: 'normal',
    paddingLeft: theme.spacing.small,
    position: 'relative',

    '&:before': {
      content: '"—"',
      position: 'absolute',
      left: '0'
    }
  })
)

export function DsQuote({
  attribution = '',
  quote = '',
  src = ''
}: DsQuoteProps) {
  return (
    <StyledFigure className='ds-quote'>
      <StyledBlockquote>
        <StyledParagraph className='ds-editorial'>{quote}</StyledParagraph>
      </StyledBlockquote>
      {src && (
        <StyledImageContainer>
          <DsImage src={src} />
        </StyledImageContainer>
      )}
      {attribution && <StyledCite>{attribution}</StyledCite>}
    </StyledFigure>
  )
}

export default DsQuote
