import React from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'
import { useRecoilState, useRecoilValue } from 'recoil'
import { calcState, remediateResultState } from '../../../state'
import CurrencyFormat from 'react-currency-format'

export interface DsCalculatorRemediateProps {
  name?: string
}

const StyledContainer = styled.div(() =>
  mq({
    marginBottom: theme.spacing.small,
    marginTop: theme.spacing.medium,
    width: ['100%', '100%', '650px']
  })
)

const StyledPair = styled.div(() =>
  mq({
    display: ['block', 'block', 'flex'],
    justifyContent: ['initial', 'initial', 'space-between']
  })
)

const StyledLabel = styled.label(() =>
  mq({
    fontWeight: '200 !important',
    paddingTop: '12px',
    width: ['300px', '300px', '70%']
  })
)

const StyledNum = styled.div(() =>
  mq({
    fontWeight: '200 !important',
    paddingBottom: ['12px', '12px', '0px'],
    paddingTop: ['0px', '0px', '12px']
  })
)

const StyledInput = styled.input(() =>
  mq({
    background: theme.colors.grayscale.darker,
    border: `1px solid ${theme.colors.grayscale.lighter}`,
    color: theme.colors.grayscale.lighter,
    height: '20px',
    justifyContent: ['start', 'start', 'right'],
    marginBottom: theme.spacing.xxSmall,
    marginTop: theme.spacing.xxSmall,
    paddingBottom: '12px',
    paddingTop: '12px',
    textAlign: 'right',
    width: ['95%', '95%', '150px']
  })
)

const StyledFormula = styled.div(() =>
  mq({
    boxShadow: `40px -20px ${theme.colors.grayscale.dark}`,
    backgroundColor: theme.colors.grayscale.darker,
    color: theme.colors.grayscale.light,
    display: ['block', 'block', 'inline-block'],
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing.small,
    width: ['100%', '100%', '450px', '700px', '850px']
  })
)

const StyledSuperScript = styled.div(() =>
  mq({
    color: `${theme.colors.grayscale.light} !important`,
    marginBottom: ['8px !important', '8px !important', '0px !important'],
    marginTop: ['-5px !important', '-5px !important', '-19px !important'],
    paddingBottom: '0',
    paddingTop: '0',
    width: ['100%', '100%', '70%']
  })
)

export function DsCalculatorRemediate({}: DsCalculatorRemediateProps) {
  interface Inputs {
    name: string
    placeholder: string
  }

  // function for the input boxes; as the input changes, it will update
  // the state values
  function CalcInput(props: Inputs) {
    const [remediate, setRemediate] = useRecoilState(calcState)

    const formChange = (event: any) => {
      if (!isNaN(event.target.value)) {
        setRemediate((currentState: any) => ({
          ...currentState,
          remediation: {
            ...currentState.remediation,
            [event.target.name]: event.target.value
          }
        }))
      }
    }

    return (
      <StyledInput
        name={props.name}
        placeholder={props.placeholder}
        onChange={(e) => formChange(e)}
        autoComplete='off'
      />
    )
  }

  // this is the output part of the calculator;
  // uses the helper function in the state.js file that's
  // related to this calculator and gets the recoilvalue
  function CalcOutput() {
    const result = useRecoilValue(remediateResultState)

    return (
      <>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            Estimated Total Cost of Rework With a Design System:
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.newTotal}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={'$'}
            />
          </StyledNum>
        </StyledPair>
        <hr />
        <br />
        <h2 style={{ color: theme.colors.grayscale.light }}>
          Potential Savings Using Design System
        </h2>
        <StyledPair>
          <StyledLabel className='ds-eyebrow-headline'>
            Estimated Net Savings with Design System:
          </StyledLabel>
          <StyledNum>
            <CurrencyFormat
              value={result.savings}
              decimalScale={2}
              displayType={'text'}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={'$'}
            />
          </StyledNum>
        </StyledPair>
        <StyledSuperScript className='ds-small-body'>
          Assuming 5% cost reduction with a Design System
        </StyledSuperScript>
      </>
    )
  }

  return (
    <>
      <StyledContainer>
        <StyledFormula>
          <h2 style={{ color: theme.colors.grayscale.light }}>
            Your Organization Today
          </h2>
          <form>
            <StyledPair>
              <StyledLabel className='ds-eyebrow-headline'>
                Est. Time Spent on Unplanned Rework
                <br />
                (% per Year):
              </StyledLabel>
              <CalcInput name='work' placeholder='1.5' />
            </StyledPair>
          </form>
          <hr />
          <br />
          <h2 style={{ color: theme.colors.grayscale.light }}>
            Estimated Rework Cost
          </h2>
          <CalcOutput />
        </StyledFormula>
      </StyledContainer>
    </>
  )
}

export default DsCalculatorRemediate
