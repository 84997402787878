const STATIC_IMAGES_PATH = '/images'

export const SITE_CONFIG = {
  siteUrl: 'https://rangle.io',
  siteLanguageMeta: 'en',
  siteTitleMeta: 'Rangle.io', // This allows an alternative site title for meta data for pages.

  shareImage: `https://res.cloudinary.com/rangle/image/upload/w_700/rangle.io/favicon-largest.png`, // fallback image for meta data. Will be used, when no post/tag/author image specified. 1200x1200 is recommended
  shareImageWidth: 700, // Change to the width of your default share image
  shareImageHeight: 700, // Change to the height of your default share image

  tileColor: '#FAFAFA',
  shortTitle: `Rangle.io`, // Used for App manifest e.g. Mobile Home Screen
  // siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
  // backgroundColor: `#e9e9e9`, // Used for Offline Manifest
  // themeColor: `#15171A`, // Used for Offline Manifest
  appleTouchIcons: [
    // Dimensions of each icon file. The files in question are square.
    57,
    114,
    72,
    144,
    60,
    120,
    76,
    152
  ].map((size) => ({
    sizes: `${size}x${size}`,
    // for instance: /images/apple-touch-icon-57x57.png
    path: `${STATIC_IMAGES_PATH}/apple-touch-icon-${size}x${size}.png`
  })),
  favicons: [
    // Dimensions of each icon file. The files in question are square.
    196,
    96,
    32,
    16,
    128
  ].map((size) => ({
    sizes: `${size}x${size}`,
    path: `https://res.cloudinary.com/rangle/image/upload/w_${size}/rangle.io/favicon-largest.png`
  })),

  twitterHandle: '@Rangleio'
}
