import React, { useEffect, useState } from 'react'
import { withNavState, withLocation } from 'utils'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { theme, DsNextChapter } from '@rangleio/ds'

import { Meta } from './helmets/Meta'
import '../styles/global.css'

const BaseHubPage = ({
  chapters = [],
  updateNavThemes,
  title,
  metaImage = [],
  description,
  location: { location },
  ...props
}) => {
  const [currentHubPage, setCurrentHubPage] = useState<string>(
    chapters[0]?.props?.slug || ''
  )

  const getUrlParams = (): URLSearchParams => {
    return new URLSearchParams(location.search)
  }

  const getChapterUrl = (slug: string): string => {
    const params = getUrlParams()
    let previewId: string
    params.forEach((value, key) => {
      if (!value) {
        previewId = key
        params.delete(key)
      }
    })
    params.delete('section')
    params.append('section', slug)
    return `?${previewId || ''}&${params.toString()}`
  }

  useEffect(() => {
    const section = getUrlParams().get('section')
    if (section !== currentHubPage) {
      setCurrentHubPage(section)
    }
  }, [location.search])

  useEffect(() => {
    updateNavThemes({ topBlockTheme: 'light', pageTheme: 'light' })
    const section = getUrlParams().get('section')
    const isValidChapter: boolean = chapters.some(
      (chapter) => chapter.props.slug === section
    )
    if (!isValidChapter) {
      setCurrentHubPage(chapters[0].props.slug)
      navigate(getChapterUrl(chapters[0].props.slug), { replace: true })
    } else {
      setCurrentHubPage(section)
    }
  }, [])

  return (
    <>
      <Meta title={title} image={metaImage[0]?.url} description={description} />
      <div className='radius cms-page' data-page>
        <div className='hub-page'>
          <nav className='hub-page-nav'>
            <p className='ds-eyebrow-headline'>CHAPTERS</p>
            <hr className='horizontal-line' />
            {chapters &&
              chapters.map((chapter, index) => (
                <div key={chapter.key} className='hub-page-item'>
                  <GatsbyLink
                    className='hub-nav-link-text'
                    style={{
                      color:
                        currentHubPage === chapter.props.slug
                          ? theme.colors.redscale.darker
                          : theme.colors.grayscale.dark
                    }}
                    to={getChapterUrl(chapter.props.slug)}
                  >
                    {index + 1}. {chapter.props.title}
                  </GatsbyLink>
                </div>
              ))}
            <hr className='horizontal-line' />
          </nav>
          <div className='hub-page-chapters-container'>
            <div style={{ display: 'grid', width: 'inherit' }}>
              {chapters &&
                chapters.map((chapter, index, arr) => (
                  <div
                    style={{
                      width: 'inherit',
                      overflow:
                        currentHubPage === chapter.props.slug ? 'auto' : 'clip',
                      height:
                        currentHubPage === chapter.props.slug ? '100%' : '0',
                      visibility:
                        currentHubPage === chapter.props.slug
                          ? 'visible'
                          : 'collapse'
                    }}
                    key={chapter.key}
                  >
                    <p
                      id={chapter.props.slug}
                      className='anchor ds-eyebrow-headline'
                    >
                      {title}: {chapter.props.title}
                    </p>
                    <p>{chapter.props.chapterContent}</p>
                    <DsNextChapter
                      hubPage
                      ctaHeading={arr[index - 1] ? 'Previous Chapter' : ''}
                      ctaLinkText={
                        arr[index - 1] ? arr[index - 1].props.title : ''
                      }
                      ctaSlug={
                        arr[index - 1]
                          ? `${props.slug}?section=${arr[index - 1].props.slug}`
                          : ''
                      }
                      navigationHeading={arr[index + 1] ? 'Next Chapter' : ''}
                      navigationLinkText={
                        arr[index + 1] ? arr[index + 1].props.title : ''
                      }
                      navigationSlug={
                        arr[index + 1]
                          ? `${props.slug}/?section=${
                              arr[index + 1].props.slug
                            }`
                          : ''
                      }
                      frameworkLinkTag={GatsbyLink}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const HubPage = withNavState(withLocation(BaseHubPage))
