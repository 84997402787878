import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'
import { mq, theme } from '../../../theme'
import { DsRichText } from '../../Molecules/DsRichText'

type DsAccordionItem = {
  summary: string,
  content: string
}
export type DsAccordionProps = {
  accordionItems: DsAccordionItem[]
}
const StyledDsAccordion = styled.div(() =>
  mq({
    borderTop: `solid ${theme.colors.grayscale.light} 1px`,
    paddingBottom: theme.spacing.small
  })
);

const StyledDsAccordionItem = styled.div(() =>
  mq({
    paddingTop: theme.spacing.medium,
    paddingBottom: theme.spacing.medium,
    borderBottom: `solid ${theme.colors.grayscale.light} 1px`,
  })
);
const StyledDsAccordionItemHeader = styled.div(() =>
  mq({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '*': {
      transition: theme.transitions.durations.medium,
    },
    ':hover *': {
      color: `${theme.colors.redscale.darker}!important`,
      stroke: `${theme.colors.redscale.darker}!important`,
    },
  })
);

const StyledDsAccordionSummary = styled.p(() =>
  mq({
    margin: '0!important',
  })
);
const StyledDsAccordionContent = styled.div(({ isOpened, elementRef }: { isOpened: boolean, elementRef: React.RefObject<HTMLDivElement> }) => {
  return mq({
    transition: theme.transitions.durations.medium,
    overflow: 'hidden',
    maxHeight: !isOpened ? '0px' : elementRef.current?.scrollHeight,
    display: 'flex'
  })
});
const StyledDsAccordionContentText = styled.div(() =>
  mq({
    paddingTop: theme.spacing.medium,
    maxHeight: '0%',
  })
);
const SvgComponent = (props: any) => (
  <svg
    width={16}
    height={3}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 1.258h16M0 1.258h16" stroke="inherit" strokeWidth={2} />
  </svg>
)
const StyledDsSvg = styled(SvgComponent)`
${(props) => mq({
  right: 0,
  transform: `rotate(${props.isOpened ? 0 : -90}deg)`,
  position: 'absolute',
})}
`

const StyledSvgContainer = styled.div(() =>
  mq({
    marginLeft: '2rem'
  })
);


const DsAccordionItem = ({ summary, content }: DsAccordionItem) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const elementRef = useRef(null);
  return (
    <StyledDsAccordionItem>
      <StyledDsAccordionItemHeader onClick={() => setIsOpened(!isOpened)}>
        <StyledDsAccordionSummary>{summary}</StyledDsAccordionSummary>
        <StyledSvgContainer>
          <StyledDsSvg className='ds-svg-accordion-plus' isOpened={true} />
          <StyledDsSvg className='ds-svg-accordion-plus' isOpened={isOpened} />
        </StyledSvgContainer>
      </StyledDsAccordionItemHeader>
      <StyledDsAccordionContent
        ref={elementRef}
        isOpened={isOpened}
        elementRef={elementRef}>
        <StyledDsAccordionContentText>
          <DsRichText content={content} />
        </StyledDsAccordionContentText>
      </StyledDsAccordionContent>
    </StyledDsAccordionItem>
  );
};
export const DsAccordion = ({
  accordionItems
}: DsAccordionProps) => {
  return (
    <div>
      <StyledDsAccordion>
        {accordionItems.map(({ summary, content }, index: number) => (
          <DsAccordionItem summary={summary} content={content} key={index} />
        ))}
      </StyledDsAccordion>
    </div>
  )
}
export default DsAccordion