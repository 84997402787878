import React from 'react'
import styled from '@emotion/styled'
import { DsLink } from '../../Atoms/DsLink'
import { mq, theme } from '../../../theme'
import { DsRetinaImage } from '../../Atoms/DsRetinaImage'

export interface DsCaseStudyHeroProps {
  alt?: string
  background?: string
  logo: string
  heading: string
  src: string
  srcMobile?: string
  industry?: string
  services?: string
}

export const DsCaseStudyHero = ({
  alt = '',
  background = 'white',
  logo = '',
  heading = '',
  src = '',
  srcMobile = '',
  industry = '',
  services = ''
}: DsCaseStudyHeroProps) => {
  const StyledDsHeroContainer = styled.div(() =>
    mq({
      background: background,
      width: '100%',
      paddingBottom: theme.spacing.small,
      paddingLeft: '10vw',
      paddingRight: '10vw',
      paddingTop: theme.spacing.small,
      position: 'relative',
      display: 'inline-block',
      marginTop: [theme.spacing.large, theme.spacing.large, 0],
      marginBottom: theme.spacing.xLarge
    })
  )

  const StyledDsHeading = styled.h1(() =>
    mq({
      margin: 0
    })
  )

  const StyledDsCaseStudyHeroContainer = styled.div`
    ${mq({
      position: 'relative'
    })}
  `

  const StyledDsLogoImage = styled(DsRetinaImage)`
    ${mq({
      maxWidth: '256px',
      margin: 0,
      paddingBottom: theme.spacing.xxSmall,
      paddingLeft: 0
    })}
  `

  const StyledDsImage = styled(DsRetinaImage)`
    ${mq({
      width: ['100%', '100%', '62vw'],
      marginRight: [0, 0, theme.spacing.small],
      paddingBottom: theme.spacing.small
    })}
  `

  const StyledDsContainer = styled.div`
    ${mq({
      display: ['block', 'block', 'flex'],
      width: '100%',
      margin: 'auto'
    })}
  `

  const StyledDsInfo = styled.div`
    ${mq({
      display: 'block',
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginBottom: theme.spacing.small
    })}
  `

  const srcSets = [
    { maxWidth: 319, resolution: 300, retina: 600 },
    { minWidth: 320, resolution: 600, retina: 1200 },
    { minWidth: 1024, resolution: 1200, retina: 2400 }
  ]

  return (
    <StyledDsCaseStudyHeroContainer className='ds-case-study-hero'>
      <StyledDsHeroContainer>
        <StyledDsLogoImage src={logo} alt={alt}></StyledDsLogoImage>
        <StyledDsHeading>{heading}</StyledDsHeading>
        <StyledDsContainer>
          <StyledDsImage
            alt={alt}
            src={src}
            srcMobile={srcMobile}
            srcSets={srcSets}
          />
          <div>
            <StyledDsInfo>
              <div className='ds-eyebrow-headline'>Industry</div>
              <div className='ds-small-body'>{industry}</div>
            </StyledDsInfo>
            <StyledDsInfo>
              <div className='ds-eyebrow-headline'>Hubs & Services</div>
              <div className='ds-small-body'>{services}</div>
            </StyledDsInfo>
          </div>
        </StyledDsContainer>
      </StyledDsHeroContainer>
    </StyledDsCaseStudyHeroContainer>
  )
}

export default DsCaseStudyHero
